import Modal from "../Modal";
import Alert from "../../../assets/Icons/Alert";
import { Button, Divider } from "@mui/material";
import PropTypes from "prop-types";

const failReasons = {
  InvalidPromoCode: "The promo code you entered is invalid. Please try again.",
  TimedOut:
    "It looks like your session has timed out due to inactivity. Please refresh the page to restart your session.",
  InvalidRate:
    "It looks like the rate you selected isn't valid. Please choose a different rate.",
  PaymentFailed: "Your payment didn't go through. Please try again.",
  MissingWebSession: "Something went wrong on our end. Please try again.",
  PromoMaxUsageReached: "This promo code has reached its usage limit.",
  SessionInViolation:
    "This plate is currently in violation status. Parking activation is no longer available.",
  InvalidCode:
    "The code you entered isn't valid. Please double-check and try again.",
  MaxUsageReached:
    "This code has reached its usage limit and is no longer valid.",
  InvalidForRate:
    "This code cannot be applied to the selected rate. Please select a different rate or use another code.",
  InvalidForLot: "This code is not valid for the lot you are parked in.",
  invalidTicket: "Unable to find the submitted barcode. Please try again",
  unknownSystemError:
    "Unknown system error. Please try again. If the issue persists try restarting the app or contacting support",
  invalidPlateOrState:
    "Invalid license plate number. Please confirm that your information is correct and try again",
  missingWebSession:
    "A system error occurred. Please try again. If the issue persists try restarting the app or contacting support. Error code 4005",
  missingTDCPriceScale:
    "A system error occurred. Please try again. If the issue persists try restarting the app or contacting support. Error code 4003",
  missingParkingSessionLotInfo:
    "A system error occurred. Please try again. If the issue persists try restarting the app or contacting support. Error code 4002",
  missingValidZonesForTicket:
    "A system error occurred. Please try again. If the issue persists try restarting the app or contacting support. Error code 4004",
  ticketRedeemed: "This parking pass has already been redeemed.",
  sessionInViolation:
    "This plate is currently in violation status. Parking activation is no longer available.",
};

const ErrorModal = (props) => {
  const { open = false, onClose, failReason, description } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <span className="mx-auto">
          <Alert />
          <div>
            <div className="text-center mt-4 font-bold text-lg">Warning</div>
            {failReason && (
              <div className="text-center mt-4">
                {failReasons[failReason] ?? failReasons["unknownSystemError"]}
              </div>
            )}
            {description && (
              <div className="text-center mt-4">{description}</div>
            )}
            <div
              className="mt-4"
              style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
            >
              <Divider />
            </div>
            <div>
              <div className="mt-4 flex justify-center">
                <Button
                  size="medium"
                  variant="contained"
                  onClick={onClose}
                  color="primary"
                  style={{
                    fontWeight: 700,
                    height: "2.5rem",
                    width: "49%",
                  }}
                >
                  Take Me Back!
                </Button>
              </div>
            </div>
          </div>
        </span>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  failReason: PropTypes.string,
  description: PropTypes.string,
};

export default ErrorModal;

import { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { Button } from "@mui/material";
import ScanQrCode from "../../../assets/Icons/ScanQrCode";
import PropTypes from "prop-types";

const QRBarcodeScanner = ({ onScanSuccess, onScanFailure }) => {
  const [scanning, setScanning] = useState(false);

  return (
    <div className="qr-scanner-container">
      {!scanning ? (
        <Button
          size="large"
          variant="contained"
          onClick={() => setScanning(true)}
          color="primary"
          className="w-full h-12 mt-4 font-bold"
          startIcon={<ScanQrCode />}
        >
          Scan QR or Barcode
        </Button>
      ) : (
        <Scanner
          onScan={(data) => {
            console.log("data", data);
            const found = data.find(
              (item) =>
                item.rawValue.includes("TBN") || item.format === "code_128"
            );
            if (found) {
              if (found.format === "code_128") {
                onScanSuccess(found.rawValue);
                setScanning(false);
              } else {
                const tbn = found.rawValue
                  .split("|")
                  .find((item) => item.includes("TBN"))
                  .replace("TBN=", "");

                onScanSuccess(tbn);
                setScanning(false);
              }
            }
          }}
          onError={onScanFailure}
          constraints={{
            facingMode: "environment",
          }}
          styles={{
            container: {
              width: "100%",
              height: "0", // hack to make the video not shift the layout
              paddingBottom: "100%", // hack to make the video not shift the layout
            },
            video: { width: "100%", height: "auto" }, // hack to make the video not shift the layout
          }}
          formats={["qr_code", "pdf417", "code_128"]}
          torch={true}
        />
      )}
    </div>
  );
};

QRBarcodeScanner.propTypes = {
  onScanSuccess: PropTypes.func.isRequired,
  onScanFailure: PropTypes.func.isRequired,
};

export default QRBarcodeScanner;
